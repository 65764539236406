import { useRef } from 'react';
import { Block } from 'types/page';
import { ReactComponent as GlobeIcon } from 'Assets/globe.svg';
import { ReactComponent as ArrowLeft } from 'Assets/arrow-left-back.svg';
import { ReactComponent as ChainIcon } from 'Assets/chain.svg';
import { PrimitiveTypes } from '../types';
import Selection from '../Common/Selection/Selection';

import styles from './NavigationSettings.module.scss';

interface IProps {
  block: Block;
  onValueChange: (attributeId: string, value: PrimitiveTypes) => void;
}

const NavigationSettings = ({ block, onValueChange }: IProps) => {
  const inputRef: React.LegacyRef<HTMLInputElement> = useRef(null);

  return (
    <div className={styles.container}>
      <Selection
        options={block.destination.options}
        selectedValue={block.destination.value}
        label={
          <div className={styles.selectionLabel}>
            <GlobeIcon /> Destination:
          </div>
        }
        onOptionSelect={(newValue) => onValueChange('destination', newValue)}
      />
      <Selection
        options={block.targetType.options}
        selectedValue={block.targetType.value}
        label={
          <div className={styles.selectionLabel}>
            <ArrowLeft className={styles.mirror} /> Open As
          </div>
        }
        onOptionSelect={(newValue) => onValueChange('targetType', newValue)}
      />
      {block.url.visible && (
        <div
          className={styles.urlInputContainer}
          onClick={() => {
            if (inputRef.current) inputRef.current.focus();
          }}
        >
          <ChainIcon />
          https://
          <input
            ref={inputRef}
            value={block.url.value?.replaceAll('https://', '')}
            onChange={(e) => {
              const newValue =
                'https://' + e.target.value.replaceAll('https://', '');
              onValueChange('url', newValue);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default NavigationSettings;
