import { LegacyRef, useRef } from 'react';
import { Block } from 'types/page';
import { loadFile } from 'utils/functions';
import { ReactComponent as ArrowDownIcon } from 'Assets/icons/arrowDown.svg';
import ToolbarManipulatorSubmenuButton from '../../Common/ToolbarManipulatorSubmenuButton/ToolbarManipulatorSubmenuButton';
import { getImageCoverTemplate } from 'CoverEditor/tmp_data';

import styles from '../UploadAction/UploadAction.module.scss';

interface IProps {
  text: string;
  block: Block;
  enabled?: boolean;
}

const UploadBookCoverAction = ({ block, text, enabled }: IProps) => {
  const inputFileRef: LegacyRef<HTMLInputElement> = useRef(null);

  const handleClick = () => {
    if (!inputFileRef.current) return;
    inputFileRef.current.click();
  };

  const handleInputClick = () => {
    if (!inputFileRef.current) return;

    const files = inputFileRef.current.files;
    if (!files) return;

    const file = files[0];
    loadFile(file, handleFileLoad);
  };

  const handleFileLoad = (file: string | ArrayBuffer | null) => {
    if (!file) return;

    switch (block.type) {
      case 'BookCover':
        console.log('TEST')
        let template = getImageCoverTemplate() as any;
        template.image = file;
        block.template.onTemplateSelect(template)

        break;
      default:
        break;
    }
  };

  return (
    <>
      <ToolbarManipulatorSubmenuButton
        className={styles.button}
        onClick={handleClick}
        enabled={enabled}
        Icon={ArrowDownIcon}
        text={text}
      />
      <input
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        ref={inputFileRef}
        onChange={handleInputClick}
      />
    </>
  );
};

export default UploadBookCoverAction;
