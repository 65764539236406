import { ReactNode, useState } from 'react';
import { ReactComponent as ArrowLeftIcon } from 'Assets/arrow-left.svg';
import { ReactComponent as CheckMarkIcon } from 'Assets/check.svg';
import useElementClickedOutside from 'Hooks/useElementClickedOutside';
import { PrimitiveTypes } from '../../types';

import styles from './Selection.module.scss';
import clsx from 'clsx';

interface IProps {
  label: ReactNode;
  selectedValue: PrimitiveTypes;
  options: Array<{ label: PrimitiveTypes; value: PrimitiveTypes }>;
  onOptionSelect: (value: PrimitiveTypes) => void;
}

const Selection = ({
  label,
  options,
  selectedValue,
  onOptionSelect,
}: IProps) => {
  const [showOptions, setShowOptions] = useState(false);

  const { ref } = useElementClickedOutside(() => setShowOptions(false));

  return (
    <div
      ref={ref}
      className={clsx(styles.container, { [styles.openState]: showOptions })}
      onClick={() => setShowOptions(!showOptions)}
    >
      <span>{label}</span>
      {options.find((option) => option.value === selectedValue)?.label}
      <div className={styles.arrowDown}>
        <ArrowLeftIcon fill="black" />
      </div>
      {showOptions && (
        <ul className={styles.optionsList}>
          {options.map((option, index) => (
            <li key={index} onClick={() => onOptionSelect(option.value)}>
              {option.value === selectedValue && <CheckMarkIcon />}
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Selection;
