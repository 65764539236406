import { useState, useRef } from 'react';
import { generateAiImageBySocket } from 'utils/socket';
import { rxBadContentAlert } from 'rx/rxState';
import { getUserId } from 'utils/api';
import ImageCardsSelect from 'Components/Common/ImageCardsSelect/ImageCardsSelect';
import RerollInput from './RerollInput/RerollInput';

import styles from './BookRerollComponent.module.scss';

interface IProps {
  initialImageUrl?: string;
  onChange: (url: string) => void;
  onSubmit: (url: string) => void;
  onCancel: () => void;
}

const BookRerollComponent = (props: IProps) => {
  const [imagesUrls, setImagesUrls] = useState<string[]>(
    props.initialImageUrl ? [props.initialImageUrl] : []
  );
  const [selectedUrl, setSelectedUrl] = useState(imagesUrls[0] ?? '');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);

  const percentRef = useRef(0);
  const selectedRef = useRef<string | null>(null);

  const handleStartGeneration = async () => {
    if (!text || text.trim().length === 0 || loading) {
      return;
    }

    setLoading(true);
    const fetchedUrls: string[] = await getNewImageUrlsWithAI(text);
    const newUrls = [...imagesUrls, ...fetchedUrls];
    setSelectedUrl(newUrls[0]);
    setImagesUrls(newUrls);
    setLoading(false);

    props.onChange(newUrls[0]);
  };

  const getNewImageUrlsWithAI = async (text: string): Promise<string[]> => {
    const currentUrl = window.location.href;

    const payload: any = {
      text,
      imageCount: 4,
      ratio: '1:1'
    };

    const userId = getUserId();
    if (userId) {
      payload.userId = userId;
    }

    const urlParts = currentUrl.split('/');
    if (currentUrl.includes('/edit/edit/')) {
      const pageId = urlParts[urlParts.length - 2];
      payload.pageId = pageId;
    }else if(currentUrl.includes('/edit/book-editor/')){
      const bookId = urlParts[urlParts.length - 1];
      payload.bookId = bookId;
    }

    const response: any = await generateAiImageBySocket({
      payload,
      callback: (response: any) => handleAiCallback(response.error),
    });

    return response.imageUrls as string[];
  };

  const handleAiCallback = (error?: string) => {
    if (error) {
      console.error('IMAGE GENERATION ERROR', error);
      setLoading(false);
      percentRef.current = 0;
      rxBadContentAlert.next(true);
    }
  };

  const handleSubmit = () => {
    props.onSubmit(selectedUrl);
    selectedRef.current = selectedUrl;
  };

  const handleClose = () => {
    props.onCancel();
  };

  const handleCardSelect = (index: number) => {
    const newUrl = imagesUrls[index];
    if (!newUrl) return;

    setSelectedUrl(newUrl);
    props.onChange(newUrl);
  };

  const handleCardRemove = (index: number) => {
    if (index < 0 || index >= imagesUrls.length) return;

    const newImageUrls = [...imagesUrls];
    newImageUrls.splice(index, 1);

    const selectedIndex = imagesUrls.findIndex((url) => selectedUrl === url);
    if (index === selectedIndex) {
      const newUrl = newImageUrls[0] ?? '';
      setSelectedUrl(newUrl);
      props.onChange(newUrl);
    }

    setImagesUrls(newImageUrls);
  };

  return (
    <div className={styles.container}>
      <RerollInput
        onSubmit={handleStartGeneration}
        text={text}
        onTextChange={setText}
      />
      {(imagesUrls.length > 0 || loading) && (
        <ImageCardsSelect
          removable
          images={imagesUrls}
          selectedCard={imagesUrls.findIndex((url) => selectedUrl === url)}
          onCardSelect={handleCardSelect}
          onCardRemove={handleCardRemove}
          loading={loading}
        />
      )}
    </div>
  );
};

export default BookRerollComponent;
